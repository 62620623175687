<template>
  <b-card>
    <div class="mb-2">
      <h4 class="text-2xl text-black ">
        <strong>Conversation Coin History</strong>
      </h4>
    </div>

    <b-row
      v-show="isLoadingCard"
    >
      <b-col>
        <b-skeleton-img class="rounded" />
      </b-col>
      <b-col>
        <b-skeleton-img class="rounded" />
      </b-col>
      <b-col>
        <b-skeleton-img class="rounded" />
      </b-col>
      <b-col>
        <b-skeleton-img class="rounded" />
      </b-col>
    </b-row>
    <div
      v-show="!isLoadingCard"
      class="grid grid-cols-4 gap-4"
    >
      <b-card
        v-for="(item, index) in dataCard"
        :key="index"
        class="border"
      >
        <div class="grid gap-8">
          <div class="flex justify-between items-center font-normal text-base leading-none tracking-normal align-middle">
            <span class="!capitalize">{{ capitalizer(item.template_type) }}</span>
            <span class="text-[#F95031]">{{ item.total_message_sent }}</span>
          </div>
          <div class="flex items-center gap-1">
            <span class=" font-normal text-base leading-none tracking-normal align-middle">IDR</span> <span class="text-[#333333] font-semibold text-2xl leading-none tracking-normal align-middle">{{ item.total_cost }}</span>
          </div>
        </div>
      </b-card>
    </div>
    <div>
      <BTable
        id="scroll"
        ref="scroll"
        :items="items"
        :fields="fieldsHistoryBroadcastForBrandsTable"
        :busy="isLoading"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        sticky-header="500px"
        class="mt-1"
        @scroll.native="handleScroll"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>
        <template #cell(sended_at)="data">
          <span>{{ DAY_MONTH_YEAR(data.item.sended_at) }}</span>
        </template>
        <template #cell(template_type)="data">
          <span>{{ capitalizer(data.item.template_type) }}</span>
        </template>
        <template #cell(cost)="data">
          <span class="text-primary">Rp{{ data.item.cost }}</span>
        </template>
      </BTable>
    </div>
  </b-card>
</template>
<script>
import { DDMMYYYY, DAY_MONTH_YEAR } from '@/libs/formatDate'
import { apiHistoryBroadcastForBrand, apiInfoBroadcastForBrand } from './Broadcast/service/api.broadcast.service'
import { fieldsHistoryBroadcastForBrandsTable } from './Broadcast/config'

export default {
  data() {
    return {
      isLoading: false,
      isLoadingCard: false,
      limit: 10,
      offset: 0,
      isLastData: false,
      dataCard: [],
      items: [],
      fieldsHistoryBroadcastForBrandsTable,
      DAY_MONTH_YEAR,
    }
  },
  mounted() {
    this.getInfoBroadcst()
    this.getHistoryBroadcast()
  },
  methods: {
    capitalizer(str) {
      return str
        .toLowerCase()
        .replace(/\b\w/g, char => char.toUpperCase())
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.getHistoryBroadcast()
      }
    },
    async getHistoryBroadcast() {
      if (this.isLoading || this.isLastData) return
      try {
        this.isLoading = true
        const { data } = await apiHistoryBroadcastForBrand(this.limit, this.offset)
        if (data) {
          this.items = [...this.items, ...data.data]
        }
        this.offset = this.items.length
        if (!data || data.data.length < this.limit) {
          this.isLastData = true
        }
        this.isLoading = false
      } catch {
        this.$toast_error({
          message: 'Gagal mengambil data history broadcast',
        })
      } finally {
        this.isLoading = false
      }
    },
    async getInfoBroadcst() {
      try {
        this.isLoadingCard = true
        const { data } = await apiInfoBroadcastForBrand()
        this.dataCard = data.data
        console.log(this.dataCard)
      } catch {
        this.$toast_error({
          message: 'Gagal mengambil data infor broadcast',
        })
      } finally {
        this.isLoadingCard = false
      }
    },
  },
}
</script>
